import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PreferencesService } from '../../preferences/preferences.service';

@Injectable({
  providedIn: 'root',
})
export class CdnPostsService {
  private data: null | any = null;
  private loaded = false;

  constructor(private preferences: PreferencesService) {}

  cdn(version: string): string {
    let cdn = '';
    cdn = `${environment.cdncms}${version}/adsCMS-posts-2024.12.01.json`;
    return cdn;
  }

  async getPostContent() {
    if (!this.loaded) {
      try {
        const response = await fetch(this.cdn(environment.version));
        if (response.ok) {
          const data = await response.json();
          this.loaded = true;
          this.data = data.data.data.posts.nodes;
        } else {
          console.error(`Fetch error`);
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (this.data) {
      return this.data;
    }
    return null;
  }
}
