import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CdnMenuService {
  public data: null | any = null;
  private loaded = false;

  constructor() {}

  cdn(version: string): string {
    return `${environment.cdncms}${version}/adsCMS-menu-2024.12.01.json`;
  }

  async getData() {
    if (!this.loaded) {
      try {
        const response = await fetch(this.cdn(environment.version));
        if (response.ok) {
          const data = await response.json();
          this.loaded = true;
          this.data = data?.data.menu.menuItems.nodes;
        } else {
          console.error(`Fetch error`);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
}
